body {
  color: #444e5b;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "proxima-nova", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #ddfa36;
  text-decoration: none;
  background-color: transparent;
  margin: none;
}

.social-link:hover {
  color: #2ae00d;
  cursor: pointer;
}
.brutal-link {
  color: #ffffff;
  cursor: pointer;
}
.brutal-link:hover {
  color: #eceff3;
  cursor: pointer;
}

h1 {
  font-size: 2.5rem;
  color: #4a69bd !important;
  font-family: "proxima-nova", sans-serif !important;
}
.h2,
h2 {
  color: #4a69bd;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 1.6rem;
}
.h3,
h3 {
  color: #4a69bd;
  font-size: 1.3rem;
  font-weight: 200rem;
}
.h4,
h4 {
  color: #536073;
  font-size: 1.15rem;
  font-weight: 100rem;
}

.texto-slide {
  color: white !important;
  font-size: 2.75rem;
  font-weight: 400 !important;
  font-family: "proxima-nova", sans-serif !important;
}

.texto-slide-azul {
  color: #34509a !important;
  font-size: 2.75rem;
  font-weight: 400 !important;
  font-family: "proxima-nova", sans-serif !important;
}

.titulo-cronologia {
  font-size: 1.5rem;
  color: #4a69bd !important;
  font-family: "proxima-nova", sans-serif !important;
}
.texto-cronologia {
  font-size: 15px;
}
.card-body {
  flex: 1 1 auto;
  padding: 0.7rem 0.78rem 0 0.78rem;
}
.texto_iconos {
  font-size: 15px;
}

.logo {
  background-image: url(./images/logo.png);
  width: 213px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 50px;
  margin-right: auto;
}

.logo_mobile {
  display: none;
}
.navbar-brand {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  padding-top: none !important;
  padding-bottom: none !important;
}
.navbar {
  font-family: "proxima-nova", sans-serif !important;
  padding-right: 5%;
  padding-top: none !important;
  padding-bottom: none !important;
  font-weight: 500 !important;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar li a {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  letter-spacing: 2px !important;
  color: #444 !important;
  text-transform: uppercase;
  padding: 1.5em;
}
.navbar-collapse {
  flex-grow: 0;
}

.bg-light {
  background-color: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #4b5864 !important;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  letter-spacing: 2px !important;
  text-transform: uppercase;
  padding: 1.8em;
  font-weight: 500 !important;
  font-style: normal;
}
.navbar li a {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  letter-spacing: 2px !important;
  color: #444 !important;
  text-transform: uppercase;
  padding: 1.5em;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #92a1ae;
  border-radius: 0.25rem;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: #92a1ae;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.space {
  width: 100%;
  display: block;
  margin-top: 86px;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 0.9;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 30px;
  height: 30px;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 10px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 5px;
  color: #fff;
  text-align: center;
}
.btn-contact {
  box-sizing: border-box;
  font-size: 14px;
  color: white;
  font-weight: bold;
  background-color: #1e3799;
}
.btn {
  display: inline-block;
  text-align: center;
}

.btn-primary {
  color: #1e3799;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #1e3799;
  background-color: #eceff3 !important;
  border-color: #b9c9d7;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #1e3799;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: white;
  background-color: #0062cc;
  border-color: #005cbf;
}
.dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-menu {
  color: white;
  top: 80%;
  min-width: 3rem !important;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
}

.dropdown-item {
  color: #fff;
  padding: 0.8rem 1.6rem 0rem 1.6rem !important;
}
a.dropdown-item.active {
  color: #fff;
  padding: 0.8rem 1.6rem 0rem 1.6rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #cbd9e4;
}

.btn-outline-light:disabled {
  color: white;
  background-color: #1e3799;
}

.language {
  color: #1e3799;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  background-color: #f8f9fd;
  border-color: #b9c9d7;
  margin-top: 0.65rem;
  width: 5rem;
}

.color-button {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  letter-spacing: 2px !important;
  color: white;
  font-weight: bold;
  background-color: #1e3799;
}

.color-button:hover {
  color: white;
  background-color: #0c2461;
}
.btn-yellow {
  background-color: #ddc986;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  letter-spacing: 2px !important;
  color: #052d9a;
  font-weight: bold;
}
.btn-yellow:hover {
  background-color: #dcb945;
  color: #022584;
}

.bg-degrade {
  background-color: #1e3799;
  background-image: linear-gradient(270deg, #1e3799 0%, #6a89cc 100%);
}
.bg-gris {
  background-color: rgba(0, 0, 0, 0.03);
}

.bg-perla {
  background-color: #f0f1f5;
}

.texto_bajada {
  font-size: 18px;
}

.texto_azul {
  font-size: 18px;
  color: #4a69bd;
  font-weight: bold;
}
.texto_azul2 {
  font-size: 17px;
  color: #25469e;
}
.azul {
  color: #25469e;
}
.texto-trx {
  color: #2c6734 !important;
}

.texto-elux {
  color: #dc8c01 !important;
}

.texto_contacto {
  font-size: 14px;
}

.statistic {
  font-family: proxima-nova, sans-serif !important;
  color: #fff !important;
  font-size: 5em;
  font-weight: lighter;
}
.white-text {
  color: white;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
}

.margin-bottom {
  height: 25px;
}
.mail {
  color: #4a69bd;
  font-weight: bold;
}

a {
  color: #fce186;
  text-decoration: none;
  background-color: transparent;
  margin: none;
}

.social-link {
  color: #fce186;
  cursor: pointer;
}
.social-link:hover {
  color: #dcb945;
  cursor: pointer;
}

.brutal-link {
  color: #ffffff;
  cursor: pointer;
}
.brutal-link:hover {
  color: #eceff3;
  cursor: pointer;
}
.bg-footer {
  background-color: #1a3087;
}
.line {
  color: #a4b3b7;
  background-color: #d8e0e7;
  height: 0.5px;
  border-color: #d8e0e7;
}

hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #a4b3b7 -moz-use-text-color #a4b3b7;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}
select {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: #ffffff;
  cursor: default;
  margin: 0em;
  font: 400 14px system-ui;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #eceff3;
  border-image: initial;
}

option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  padding-top: 0px;
  padding-right: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
}

/*FLECHA IR ARRIBA*/
.ir-arriba {
  display: none;
  padding: 20px;
  background: #626e79;
  opacity: 0.8;
  cursor: pointer;
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 10000;
  justify-content: center;
}
.arrow {
  width: 200%;
  justify-content: center;
}

.gestion_image {
  margin: 0 auto;
}

/* ANIMACIÓN */

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 27));
    transform: translateX(calc(-250px * 27));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 27));
    transform: translateX(calc(-250px * 27));
  }
}
.slider {
  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before,
.slider::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 70s linear infinite;
  display: -webkit-box;
  display: flex;
  width: calc(25px * 34);
}
.slider .slide {
  height: 100px;
  width: 200px;
}

/*-------*/

@media screen and (max-width: 42rem) {
  .slider .slide-track {
    width: calc(250px * 14);
  }

  h1 {
    font-size: 1.8rem;
  }

  .h2,
  h2 {
    font-size: 1.1rem;
  }
  .h3,
  h3 {
    font-size: 1.2rem;
  }
  .h4,
  h4 {
    font-size: 0.93rem;
  }

  .texto-slide {
    font-size: 1.5rem;
    font-weight: 600 !important;
    margin-top: 55%;
  }

  .texto-slide-azul {
    font-size: 1.5rem;
    font-weight: 600 !important;
    margin-top: 55%;
  }

  .space {
    margin-top: 100px;
  }

  .statistic {
    font-size: 3em;
  }

  .white-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  .gestion_image {
    width: 100%;
  }
}
